import { __ } from 'i18n';
import { _converse, api } from "@converse/headless/core";
import { html } from "lit";

export default (o) => {

    let unread = 0;

    if (api.connection.connected()) {
        const { chatboxes } = _converse;
        chatboxes.forEach(room => {
            unread += room.get('num_unread') ?? 0;
        });
    }
    return html`<a id="toggle-controlbox" class="toggle-controlbox position-relative ${o.hide ? 'hidden' : ''}" @click="${o.onClick}"><span class="message-indicator ${unread > 0 ? '' : 'hidden'}">${unread} </span><span class="toggle-feedback"><converse-icon class="fa fa-message converse-icon-white" size="1em"></converse-icon></span></a>`;
}
