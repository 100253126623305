import { converse, api } from '@converse/headless/core';

converse.plugins.add('rest-api-plugin', {
    initialize: function () {
        var _converse = this._converse;
        _converse.on('connected', function () {
            var currentUser = _converse.connection;
            RestApiPlugin._user = currentUser.authcid;
            RestApiPlugin._pass = currentUser.pass;
            RestApiPlugin._BASE_URL = api.settings.get('apivgh_path');
        });
    }
});

export const HttpStatus = {
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    NO_CONTENT: 204,
    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    METHOD_NOT_ALLOWED: 405,
    INTERNAL_SERVER_ERROR: 500,
    SERVICE_UNAVAILABLE: 503
}

export class RestApiPlugin {
    static _BASE_URL = "";

    static _user = "";
    static _pass = "";

    static get _authHeader() {
        const authToken = btoa(`${this._user}:${this._pass}`);
        return {
            authorization: `Basic ${authToken}`
        };
    }

    static _header(auth = false) {
        return {
            ...(auth ? this._authHeader : undefined),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }

    static _post(url, body, auth = false) {
        return fetch(`${this._BASE_URL}/${url}`,
            {
                method: 'POST',
                headers: this._header(auth),
                body,
            }
        );
    }

    static _get(url, auth = false) {
        return fetch(`${this._BASE_URL}/${url}`,
            {
                method: 'GET',
                headers: this._header(auth),
            }
        );
    }

    static _delete(url, auth = false) {
        return fetch(`${this._BASE_URL}/${url}`,
            {
                method: 'DELETE',
                headers: this._header(auth),
            }
        );
    }

    /**
     * @example
     * report body: JSON.stringify({
     *    messageID: string,
     *    username: string,
     *    note: string
     * })
     * @param {*} reportBody 
     * @returns {Promise}
     */
    static sendReport(reportBody) {
        return this._post('reports', reportBody, true);
    }

    /**
     * @param {string} blocked jid
     * @returns {Promise}
     */
    static blockUser(blocked) {
        return this._post('blocked-chats', JSON.stringify({ blocked: blocked.split('@')[0] }), true);
    }

    /**
     * 
     * @param {number} blockID blockedChatID 
     * @returns {Promise}
     */
    static unblockUser(blockID) {
        return this._delete(`blocked-chats/${blockID}`, true);
    }

    static getBlockedUser() {
        return this._get(`blocked-chats`, true);
    }

}
