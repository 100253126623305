import { converse, api } from '@converse/headless/core';
import log from 'headless/log';

converse.plugins.add('update-vcard-cache', {
    
    initialize: function()
    {
        console.log('update-vcard-cache: initialize registered');
        var _converse = this._converse;
        
        /*
        _converse.on('connected', function () {
            console.log('update-vcard-cache: connected');
        
            Promise.all([
                _converse.api.waitUntil('rosterContactsFetched'),
                _converse.api.waitUntil('chatBoxesFetched'),
                _converse.api.waitUntil('chatRoomsFetched'),
                _converse.api.waitUntil('rosterInitialized'),
            ]).then(async function () {
                console.log('update-vcard-cache: aggiornamento vcard per tutti i contatti');
                _converse.api.contacts.get().then((contacts) => {
                    contacts.map(async (contact) => {
                        const jid = contact.get('jid');
                        try {
                            console.log(`update-vcard-cache: aggiornamento vcard OK per ${jid} ...`);
                            _converse.api.vcard.get(jid).then(() => {
                                if (! contact.vcard.fn) contact.vcard.fn = jid.split('@')[0];
                                if (! contact.vcard.nickname) contact.vcard.nickname = jid.split('@')[0];
                                _converse.api.vcard.update(contact.vcard, true);
                                console.log(`update-vcard-cache: aggiornamento vcard OK per ${jid}.`);
                            })
                        } catch (error) {
                            console.error(`update-vcard-cache: errore aggiornamento vcard per ${jid}:`, error);
                        }
                    });
                });

                // update current logged user vcard
                try {
                    await _converse.api.vcard.update(_converse.bare_jid, true);
                    console.log(`update-vcard-cache: aggiornamento vcard OK current logged user ${_converse.bare_jid}`);
                } catch (error) {
                    console.error(`update-vcard-cache: errore aggiornamento vcard per ${_converse.bare_jid}:`, error);
                }
            });
        });
        */

        _converse.on('contactPresenceChanged', async function (data) {
            try {
                if (data.presence.changed.show == 'online') {
                    console.log('update-vcard-cache: contactPrecenceChanged to online - aggiornamento vcard per jid: ', data.attributes.jid);
                    await _converse.api.vcard.update(data.attributes.jid, true);
                    console.log(`update-vcard-cache: contactPrecenceChanged to online - aggiornamento vcard OK per ${data.attributes.jid}:`);
                }
            } catch (error) {
                console.error(`update-vcard-cache: contactPrecenceChanged errore aggiornamento vcard per ${data.attributes.jid}:`, error);
            }
        });
    }
})

        