import tplSpinner from "templates/spinner.js";
import { _converse, api, converse } from "@converse/headless/core.js";
import { html } from 'lit';

const { Strophe } = converse.env;


function whenNotConnected(o) {
    const connection_status = _converse.connfeedback.get('connection_status');
    if ([Strophe.Status.RECONNECTING, Strophe.Status.CONNECTING].includes(connection_status)) {
        return tplSpinner();
    }
    if (o['active-form'] === 'register') {
        return html`<converse-register-panel></converse-register-panel>`;
    }
    return html`<converse-login-form id="converse-login-panel" class="controlbox-pane fade-in row no-gutters"></converse-login-form>`;
}


export default (el) => {
    const { chatboxes, CHATROOMS_TYPE, PRIVATE_CHAT_TYPE } = _converse;
    const rooms = chatboxes.filter(m => m.get('type') === CHATROOMS_TYPE);
    let unreadGenerali = 0;
    rooms.forEach(room => {
        unreadGenerali += room.get('num_unread_general') ?? 0;
    });

    const privateChats = chatboxes.filter(m => m.get('type') === PRIVATE_CHAT_TYPE);
    let unreadPrivate = 0;
    privateChats.forEach(privateChat => {
        unreadPrivate += privateChat.get('num_unread') ?? 0;
    });

    const o = el.model.toJSON();

    return html`<div class="flyout box-flyout"><converse-dragresize></converse-dragresize><div class="controlbox-panes"><div class="controlbox-pane">${o.connected
            ? html`<converse-user-profile></converse-user-profile><div class="d-flex mt-1 toggle-navbar"><div id="converse-roster-toggler" class="w-50 text-center p-2 d-flex justify-content-center align-items-center font-bold" @click="${ev => el.toggleGroupsChats(ev)}">Gruppi<div class="${unreadGenerali > 0 ? 'toggler-unread-indicator' : ''}">${unreadGenerali > 0 ? unreadGenerali : ''}</div></div><div id="chatrooms-toggler" class="w-50 text-center p-2 d-flex justify-content-center align-items-center ${el.selectedClassToggler.join(' ')}" @click="${ev => el.togglePrivateChats(ev)}">Privata<div class="${unreadPrivate > 0 ? 'toggler-unread-indicator' : ''}">${unreadPrivate > 0 ? unreadPrivate : ''}</div></div></div><div id="chatrooms" class="controlbox-section hidden"><converse-rooms-list></converse-rooms-list></div>${api.settings.get("authentication") === _converse.ANONYMOUS ? '' :
                    html`<div id="converse-roster" class="controlbox-section"><converse-roster></converse-roster></div>`
                }`
            : whenNotConnected(o)
        }</div></div></div>`
};
