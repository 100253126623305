import { _converse, converse } from '@converse/headless/core';

export class ExportNotifications {
    static callback = (data) => { };

    static onNotification(callback) {
        this.callback = callback;
    }
}

converse.plugins.add('export-notifications', {
    initialize() {
        var _converse = this._converse;
        _converse.api.listen.once('connected', function () {
            Promise.all([
                _converse.api.waitUntil('rosterContactsFetched'),
                _converse.api.waitUntil('chatBoxesFetched'),
                _converse.api.waitUntil('chatRoomsFetched'),
                _converse.api.waitUntil('rosterInitialized'),

            ]).then(function () {
                _converse.api.listen.on('messageNotification', (data) => {
                    const message = data.attrs;
                    if (message.sender == 'them' && message.is_delayed) {
                        return;
                    }
                    const parsedData = {
                        body: message.body,
                        to: message.to,
                        from: message.from,
                        id: message.id,
                        from_muc: message.from_muc,
                        from_real_jid: message.from_real_jid,
                        is_referenced: message.references.some(
                            (val) => val.uri.includes(message.to.split("/")[0])
                        )
                    };
                    ExportNotifications.callback(parsedData);
                });
            })
        });
    }
});