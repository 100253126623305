import BaseModal from "plugins/modal/modal.js";
import reportModalTmpl from "./template/report-modal.js";
import { api, converse } from "@converse/headless/core";
import { Model } from '@converse/skeletor/src/model.js';
import Confirm from "plugins/modal/confirm.js";
import Alert from "plugins/modal/alert.js";
import { html } from "lit";
import baseModalTmpl from "./template/modal-tmpl.js";

export default class ReportModal extends BaseModal {

    constructor(options) {
        super(options);
        this.tab = 'report';
    }

    initialize() {
        super.initialize();
        this.listenTo(this.model, 'change', this.render);
    }

    get _reportMessageContent() {
        const reportField = document.getElementById('report-message-reason'),
            message = this.model.collection.models.find((val) => val.attributes.id == this.model.get('msgid')),
            id = message.attributes.id;

        if (!id) {
            throw new Error('stanza id not found');
        }

        return JSON.stringify({
            id,
            note: reportField.value
        })
    }

    toHTML() {
        return baseModalTmpl(this);
    }

    renderModal() {
        return reportModalTmpl(this);
    }

    getModalTitle() {
        return ``;
    }

    cleanForm() {
        const reportField = document.getElementById('report-message-reason');
        // clean form and close modal
        reportField.value = '';
        this.modal.hide();
    }

    sendReport() {
        // send report message
        converse
            .medialogicPlugins
            .restApiPlugin
            .sendReport(this._reportMessageContent)
            .then((value) => {
                let modelObject = {};
                if (value.ok) {
                    modelObject = {
                        title: 'Segnalazione',
                        messages: ['Segnalazione avvenuta con successo.'],
                        fields: [],
                        'type': 'confirm',
                    };
                } else if (value.status == 409) {
                    modelObject = {
                        title: 'Errore Segnalazione',
                        messages: ['Hai già segnalato questo messaggio.'],
                        fields: [],
                        'type': 'confirm',
                    };
                } else {
                    modelObject = {
                        title: 'Errore Segnalazione',
                        messages: ['Errore durante la segnalazione, riprova più tardi.'],
                        fields: [],
                        'type': 'confirm',
                    };
                }

                const model = new Model(modelObject);
                const alert = new Alert({ model });
                alert.show();
            });
    }

    async report(ev) {
        ev.preventDefault();
        try {
            await this.sendReport();
        } catch (err) {
            console.error(err);
            alert('Errore durante la segnalazione, riprovare');
        } finally {
            this.cleanForm();
            this.onHide();
        }
    }

    hide(ev) {
        ev.preventDefault();
        this.modal.hide();
    }
}

api.elements.define('converse-report-modal', ReportModal);
