
import { converse, _converse } from '@converse/headless/core';
converse.plugins.add('open-chat-buttons', {
    initialize: function () {
        var _converse = this._converse;
        _converse.on('connected', function () {
            Promise.all([
                _converse.api.waitUntil('rosterContactsFetched'),
                _converse.api.waitUntil('chatBoxesFetched')
            ]).then(function () {
                OpenChat.isLoaded = true;
            });
        });
    }
});

export class OpenChat {
    static isLoaded = false;

    static openDmChat(jid, name) {
        if (this.isLoaded) {
            _converse.api.contacts.add(jid, name);
            _converse.api.chats.open(jid).then();
        }
    }

    static openGroupChat (roomJid) {
        if (this.isLoaded) {
            _converse.api.rooms.open(roomJid).then();
        }
    }
}
