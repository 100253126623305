import { converse, api, _converse } from "@converse/headless/core";
import { HttpStatus, RestApiPlugin } from "medialogic-plugins/rest-api-plugin";

converse.plugins.add('block-user-store', {
    initialize: function () {
        var _converse = this._converse;
        _converse.on('connected', function () {
            const { blockUserStore } = converse.medialogicPlugins;
            blockUserStore.updateBlockedUserStore();
        });
    }
});

export class BlockUserStore {

    get _blockedUsers() {
        return JSON.parse(localStorage.getItem('blockedUsers')) ?? [];
    }

    set _blockedUsers(blockedUsers) {
        localStorage.setItem('blockedUsers', JSON.stringify(blockedUsers));
    }

    addBlockedUser(blocked) {
        const blockedUsers = this._blockedUsers;
        if (blockedUsers.findIndex((el) => el.blocked == blocked.blocked) == -1) {
            blockedUsers.push(blocked);
            this._blockedUsers = blockedUsers;
        }
    }

    blockUser(jid) {
        // send unavailable to blocked user
        _converse.api.user.presence.send('unavailable', jid);

        RestApiPlugin
            .blockUser(jid)
            .then((res) => {
                if (res.status == HttpStatus.CREATED || res.status == HttpStatus.OK) {
                    _converse.api.user.presence.send('unavailable', jid);
                    this.updateBlockedUserStore();
                } else {
                    alert("Errore: utente non bloccato");
                }
            });
    }

    unblockUser(jid) {
        const blockedUsers = this._blockedUsers;
        var index = this._getBlockedIndex(jid);
        if (index !== -1) {
            RestApiPlugin
                .unblockUser(blockedUsers[index].blockedChatID)
                .then((res) => {
                    if (res.status == HttpStatus.CREATED || res.status == HttpStatus.OK) {
                        this.updateBlockedUserStore();

                        // send available to unblocked user
                        _converse.api.user.presence.send('available', jid);
                    } else {
                        alert("Errore: utente non sbloccato");
                    }
                });
        }
    }

    isUserBlocked(jid) {
        return this._getBlockedIndex(jid) >= 0;
    }

    _getBlockedIndex(jid) {
        return this._blockedUsers.findIndex((el) => jid.includes(el.blocked));
    }

    updateBlockedUserStore() {
        RestApiPlugin.getBlockedUser().then(
            (value) => {
                this._blockedUsers = [];
                value.json().then(
                    (res) => {
                        res.blockedChats.forEach(el => this.addBlockedUser(el));
                        api.trigger('blockUser');
                    }
                );
            }
        );
    }
}
