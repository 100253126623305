import tplProfile from './templates/profile.js';
import { CustomElement } from 'shared/components/element.js';
import { _converse, api, converse } from '@converse/headless/core';


const u = converse.env.utils;
class Profile extends CustomElement {
    initialize () {
        this.model = _converse.xmppstatus;
        this.listenTo(this.model, "change", () => this.requestUpdate());
        this.listenTo(this.model, "vcard:add", () => this.requestUpdate());
        this.listenTo(this.model, "vcard:change", () => this.requestUpdate());
    }

    render () {
        return tplProfile(this);
    }

    showProfileModal (ev) {
        ev?.preventDefault();
        api.modal.show('converse-profile-modal', { model: this.model }, ev);
    }

    showStatusChangeModal (ev) {
        ev?.preventDefault();
        api.modal.show('converse-chat-status-modal', { model: this.model }, ev);
    }

    showUserSettingsModal (ev) {
        ev?.preventDefault();
        api.modal.show('converse-user-settings-modal', { model: this.model, _converse }, ev);
    }

    close (ev) {
        ev?.preventDefault?.();
        if (
            ev?.name === 'closeAllChatBoxes' &&
            (_converse.disconnection_cause !== LOGOUT ||
                api.settings.get('show_controlbox_by_default'))
        ) {
            return;
        }
        if (api.settings.get('sticky_controlbox')) {
            return;
        }

        u.safeSave(_converse.chatboxes.get('controlbox'), { 'closed': true });
        api.trigger('controlBoxClosed', this);
        return this;
    }
}

api.elements.define('converse-user-profile', Profile);
